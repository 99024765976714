<template>
  <div>
    <div class="myp-buy-wrap">
      <div class="search-item-wrap">
        <div class="label">등록일자</div>
        <div class="date-item-wrap">
          <input
            type="text"
            class="inp datepicker"
            inputmode="none"
            id="startDt"
            :value="startDt"
          />
          <span class="e01">-</span>
          <input
            type="text"
            class="inp datepicker"
            inputmode="none"
            id="endDt"
            :value="endDt"
          />
        </div>
        <button type="button" @click="search">검색</button>
      </div>
      <div class="tbl-scrolls">
        <table class="tbl01 type01">
          <colgroup>
            <col width="40" />
            <col width="80" />
            <col width="120" />
            <col width="" />
            <col width="" />
            <col width="" />
            <col width="" />
            <col width="" />
            <col width="70" />
            <col width="70" />
          </colgroup>
          <tr>
            <th><input type="checkbox" @click="checkAll" ref="chkAll" /></th>
            <th>&nbsp;</th>
            <th>구인공고</th>
            <th>등록일자</th>
            <th>발생금액</th>
            <th>결제금액</th>
            <th>포인트</th>
            <th>잔액</th>
            <th>결제방식</th>
            <th>근태상태</th>
          </tr>
          <tr v-for="(settlement, idx) in settlementList" :key="`st_${idx}`">
            <td>
              <input
                type="checkbox"
                :value="idx"
                v-model="chkBox"
                @change="refreshTotalPayAmt($event)"
                v-if="
                  settlement.balance > 0 &&
                  !(
                    settlement.payStatus === '미완료' &&
                    settlement.paymentType === '1'
                  )
                "
              />
            </td>
            <td>
              <button
                type="button"
                class="status hold"
                @click="showDetailPage(settlement)"
              >
                발생내역
              </button>
            </td>
            <td>{{ settlement.productName }}</td>
            <td>{{ settlement.regDt }}</td>
            <td>{{ `${insertCommaToNumber(settlement.productAmt)}` }}원</td>
            <td>{{ `${insertCommaToNumber(settlement.payAmt)}` }}원</td>
            <td>{{ `${insertCommaToNumber(settlement.pointAmt)}` }}원</td>
            <td>{{ `${insertCommaToNumber(settlement.balance)}` }}원</td>
            <td>{{ settlement.paymentType === '1' ? '선불' : '후불' }}</td>
            <td>{{ settlement.payStatus }}</td>
          </tr>
          <tr>
            <td colspan="10" v-if="numOfSettlements == 0">
              해당내역이 존재하지 않습니다.
            </td>
          </tr>
        </table>
      </div>
      <ul class="olist">
        <li>선불 : 미완료일 때 결제요청을 할 수 없습니다.</li>
        <li>
          상태 : 미완료(지급보류 + 근무중 + 대기중) > 0, 0이면 완료상태 입니다.
        </li>
        <li>선불일 때 Seq=1을 취소하고 2를 결제합니다.</li>
      </ul>
    </div>
    <!-- 페이징 추가 -->
    <Pagination
      :totalElements="totalElements"
      :currentPageIndex="currentPageIndex"
      :listRowCount="listRowCount"
      :pageLinkCount="pageLinkCount"
      @goPage="onGoPage"
    ></Pagination>
    <!-- 페이징 추가 -->
    <div class="h2-tit">
      <h2 class="mshow">결제정보</h2>
    </div>
    <div class="yk-register-form-wrap">
      <div class="form-rows-wrap mt0">
        <div class="label">포인트 사용</div>
        <div class="form-item-wrap">
          <div class="_number">
            <input
              v-model="pointToCash"
              ref="pointToCash"
              type="text"
              class="inp"
            />
          </div>
          <div class="coin-value">
            <span>보유포인트</span>
            <strong>
              {{ `${insertCommaToNumber(account.pointBalance)}` }}
            </strong>
          </div>
        </div>
      </div>
      <div class="form-rows-wrap">
        <div class="label">결제금액</div>
        <div class="form-item-wrap">
          <div class="value">
            <strong>
              {{ `${insertCommaToNumber(getTotalProductAmt)}` }}원
            </strong>
          </div>
        </div>
      </div>
      <!--div class="form-rows-wrap">
        <div class="label">결제방법</div>
        <div class="form-item-wrap">
          <label>
            <input type="radio" class="radio" name="radio" />
            <span>간편결제</span>
          </label>
          <label>
            <input type="radio" class="radio" name="radio" />
            <span>기타결제</span>
          </label>
        </div>
      </div-->
      <div class="btn-wrap" @click="payment">
        <a class="link center" style="cursor: pointer">
          <span>결제</span>
        </a>
      </div>
    </div>
    <iframe
      name="HiddenIFrame"
      id="HiddenIFrame"
      width="1027"
      height="1000"
      style="display: none"
      v-if="!isApp"
    ></iframe>
  </div>
</template>

<script>
import { getSettlementListForEmployer } from 'Api/modules'
import Pagination from 'Common/Pagination'
import datePickerMixin from 'Mixins/datePicker'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { sendMessageToApp } from 'Utils/message'
import { URL } from 'Api/api'
export default {
  components: {
    Pagination,
  },
  data() {
    return {
      chkBox: [],
      settlementList: [],
      isCheckedAll: false,
      numOfSettlements: 0,
      // paging
      pageNumber: 0,
      totalElements: 0,
      listRowCount: 10,
      pageLinkCount: 5,
      currentPageIndex: 1,
      pointToCash: '',
      startDt: '',
      endDt: '',
    }
  },
  computed: {
    ...mapGetters(['account']),
    getTotalProductAmt() {
      return (
        this.settlementList
          .filter(settlement => this.chkBox.includes(settlement.index))
          .map(settlement => Number(settlement.balance))
          .reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0,
          ) - Number(this.pointToCash.split(',').join(''))
      )
    },
  },
  mixins: [datePickerMixin],
  watch: {
    startDt(newVal) {
      if (newVal) {
        //endDate가 이미 세팅 되있는 경우
        if (this.endDt) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (this.diffCalendar(newVal, this.endDt) - 1 < 0) {
            this.setDatePickerDate('endDt', newVal, this.setEndDate)
            this.endDt = newVal
          }
        }
      }
    },
    endDt(newVal) {
      if (newVal) {
        //startDate가 이미 세팅 되있는 경우
        if (this.startDt) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (this.diffCalendar(this.startDt, newVal) - 1 < 0) {
            this.setDatePickerDate('startDt', newVal, this.setStartDate)
            this.startDt = newVal
          }
        }
      }
    },
    pointToCash() {
      this.pointToCash = this.insertCommaToNumber(this.pointToCash)
      this.refreshTotalPayAmt()
    },
    '$route.query': {
      deep: true,
      handler() {
        this.setFromQueryParam()
        this.search(false)
      },
    },
  },
  methods: {
    async payment() {
      if (this.getTotalProductAmt <= 0) {
        this.$toasted.error('결제할 금액이 존재하지 않습니다.')
        return
      }

      let paymentList = []
      for (const index of this.chkBox) {
        paymentList.push(this.settlementList[index])
      }

      // 우리쪽 컨트롤러를 통한 PG 호출
      const paymentPgUrl = URL + '/api/v1/payments/pg'
      const payForm = document.createElement('form')
      payForm.method = 'POST'

      // 주문번호(구인공고 문서번호)
      //fixme:: 문서번호와 결제로그아이디로 Moid 를 만든다.
      // const documentNo = document.createElement('input')
      // documentNo.type = 'hidden'
      // documentNo.name = 'documentNo'
      // documentNo.value = this.editingDocumentNo // todo::임의값 하드코딩
      // documentNo.value = paymentList[0]
      // ? paymentList[0].documentNo
      // : ''
      // payForm.appendChild(documentNo)

      // 거래상품명
      const goodsName = document.createElement('input')
      goodsName.type = 'hidden'
      goodsName.name = 'GoodsName'
      goodsName.value =
        paymentList.length === 1
          ? paymentList[0].documentNo
          : paymentList[0].documentNo + ' 외 ' + paymentList.length + '건'
      payForm.appendChild(goodsName)

      // 회원사고객 아이디
      const userId = document.createElement('input')
      userId.type = 'hidden'
      userId.name = 'mallUserID'
      userId.value = this.account.userId
      payForm.appendChild(userId)

      // 구매자명
      const userNm = document.createElement('input')
      userNm.type = 'hidden'
      userNm.name = 'BuyerName'
      userNm.value = this.account.userNm
      payForm.appendChild(userNm)

      // 사용 포인트
      const usedPoint = document.createElement('input')
      usedPoint.type = 'hidden'
      usedPoint.name = 'usedPoint'
      usedPoint.value = this.pointToCash.split(',').join('')
      payForm.appendChild(usedPoint)

      // 거래금액
      const amt = document.createElement('input')
      amt.type = 'hidden'
      amt.name = 'Amt'
      amt.value = this.getTotalProductAmt
      // amt.value = 1000
      payForm.appendChild(amt)

      // 지불수단
      const payMethod = document.createElement('input')
      payMethod.type = 'hidden'
      payMethod.name = 'PayMethod'
      payMethod.value = 'CARD'
      payForm.appendChild(payMethod)

      // 디바이스 유형
      const deviceType = document.createElement('input')
      deviceType.type = 'hidden'
      deviceType.name = 'deviceType'
      deviceType.value = this.isMobileDevice()
      payForm.appendChild(deviceType)

      // 결제 경로(결제결과 화면 분기용 => 1:일자리 등록 결제, 2:정산내역 결제)
      const paymentPath = document.createElement('input')
      paymentPath.type = 'hidden'
      paymentPath.name = 'paymentPath'
      paymentPath.value = '2'
      payForm.appendChild(paymentPath)

      const addInfo = document.createElement('input')
      addInfo.type = 'hidden'
      addInfo.name = 'addInfo'
      addInfo.value = JSON.stringify(paymentList)
      payForm.appendChild(addInfo)

      if (paymentList.length <= 0) {
        this.$toasted.error('결제금액을 선택하세요.')
      }

      document.body.appendChild(payForm)

      //모바일 앱이 아닐 경우 - 팝업(모바일 웹브라우저, 웹브라우저)
      sendMessageToApp('LOG', { isApp: this.isApp })
      if (this.isMobileDevice() == 'MOBILE') {
        payForm.target = ''
        // 모바일 결제중 결제 취소시 따로 화면 개발 필요함. (StopURL 값을 전달해야 한다. 서버에서 하기로 함)
        const stopUrl = document.createElement('input')
        stopUrl.type = 'hidden'
        stopUrl.name = 'StopURL'
        stopUrl.value = document.location.origin
        payForm.appendChild(stopUrl)
      } else {
        //payForm.target = 'HiddenIFrame'
        //payForm.setAttribute('target', '_blank')
        //payForm.target = ''
        payForm.target = 'SmartorPayWindow'
        window.open(
          '',
          'SmartorPayWindow',
          'width=850, height=850, resizable=0, scrollbars=no, status=0, titlebar=0, toolbar=0, left=435, top=250,',
        )
      }

      sendMessageToApp('LOG', { form: 'pay submit start.' })

      payForm.action = paymentPgUrl
      payForm.submit()
    },
    checkAll() {
      this.chkBox = this.isCheckedAll ? [] : this.getPossibleToPayArr()
      this.isCheckedAll = !this.isCheckedAll
      this.refreshTotalPayAmt()
    },
    getPossibleToPayArr() {
      let rtnArr = []
      for (let i = 0; i < this.numOfSettlements; i++) {
        if (
          this.settlementList[i].balance > 0 &&
          !(
            this.settlementList[i].payStatus === '미완료' &&
            this.settlementList[i].paymentType === '1'
          )
        ) {
          rtnArr.push(i)
        }
      }
      return rtnArr
    },
    refreshTotalPayAmt() {
      // 결제금액하고, 문서번호 등록 필요

      // 사용할 포인트를 현재 보유 포인트보다 크게 입력한 경우
      if (
        Number(this.pointToCash.split(',').join('')) > this.account.pointBalance
      ) {
        this.pointToCash = this.insertCommaToNumber(this.account.pointBalance)
      } else {
        let diff = this.getTotalProductAmt
        if (diff < 0) {
          this.pointToCash = this.insertCommaToNumber(
            Number(this.pointToCash.split(',').join('')) + diff,
          )
        }
      }
    },
    // refreshPaymentInfo(event, settlement, idx) {
    //   // 추가 정보 배열 추가
    //   if (event.target.checked === true) {
    //     // 잔액이 0이면 return;
    //     if (settlement.balance === 0) {
    //       this.$toasted.error('정산처리를 하실 잔액이 존재하지 않습니다.')
    //       // this.chkBox = this.chkBox.splice(idx, 1)
    //       // this.chkBox.splice(idx, 1)
    //       // this.$refs['chk_' + idx].checked = false
    //       return
    //     }
    //     // 선불인데, 미완료이면 return;
    //     if (
    //       settlement.payStatus === '미완료' &&
    //       settlement.paymentType === '1'
    //     ) {
    //       this.$toasted.error(
    //         '선불의 경우, 아직 근태관리가 미완료건이 있어서 정산처리를 하실 수 없습니다.',
    //       )
    //       return
    //     }
    //     for (let i = 0; i < paymentList.length; i++) {
    //       const existInfo = paymentList[i]
    //       if (existInfo.documentNo == settlement.documentNo) return
    //     }
    //     paymentList.push({
    //       documentNo: settlement.documentNo,
    //       payAmt: String(settlement.balance),
    //     })
    //     // 추가 정보 배열 삭제
    //   } else {
    //     for (let i = 0; i < paymentList.length; i++) {
    //       const existInfo = paymentList[i]
    //       if (existInfo.documentNo == settlement.documentNo) {
    //         // 배열 삭제함.
    //         paymentList.splice(i, 1)
    //         break
    //       }
    //     }
    //   }
    //   // for (let i = 0; i < paymentList.length; i++) {
    //   //   const existInfo = paymentList[i]
    //   //   if (existInfo.documentNo == settlement.documentNo) return
    //   // }
    //   // paymentList.push({
    //   //   documentNo: settlement.documentNo,
    //   //   payAmt: settlement.payAmt,
    //   // })
    //   this.refreshTotalPayAmt()
    // },
    onGoPage(curPage) {
      this.currentPageIndex = curPage
      this.pageNumber = curPage - 1 //pageNumber 는 현재페이지에서 -1
      this.search()
    },
    showDetailPage(settlement) {
      this.$emit('showDetailPage', settlement)
    },
    setStartDate(selectedDate) {
      this.startDt = selectedDate
    },
    setEndDate(selectedDate) {
      this.endDt = selectedDate
    },
    async search(setQuery = true) {
      if (!this.startDt || !this.endDt) {
        return false
      }
      // 정산 내역 조회
      const res = await getSettlementListForEmployer({
        startDt: this.startDt,
        endDt: this.endDt,
        pageNumber: this.pageNumber,
      })

      this.settlementList = res.content
      this.totalElements = res.totalElements
      this.currentPageIndex = res.pageable.pageNumber + 1
      this.pageNumber = res.pageable.pageNumber
      this.numOfSettlements = this.settlementList.length
      for (let i = 0; i < this.numOfSettlements; i++) {
        this.settlementList[i].index = i
        // 상태 확인: 선불일때는 판매테이블 구인완료수량 = 확정수량(지급확정등) + 미확정수량(Noshow)
        if (
          Number(this.settlementList[i].completionQty) > 0 &&
          Number(this.settlementList[i].completionQty) ===
            Number(this.settlementList[i].confirmQty) +
              Number(this.settlementList[i].incompleteQty)
        ) {
          this.settlementList[i].payStatus = '완료'
        } else {
          this.settlementList[i].payStatus = '미완료'
        }
        // this.settlementList[i].payStatus =
        //   this.settlementList[i].undecidedCnt > 0 ? '미완료' : '완료'
      }

      if (setQuery) {
        const query = {
          startDt: this.startDt,
          endDt: this.endDt,
          pageNumber: this.pageNumber,
        }

        this.$router.push({
          name: 'mypageCalcHistory',
          query,
        })
      }
      this.chkBox = []
      this.isCheckedAll = false
      this.pointToCash = ''
      this.$refs['chkAll'].checked = false
    },
    setFromQueryParam() {
      const { startDt, endDt, pageNumber } = this.$route.query
      this.startDt = startDt
      this.setDatePickerDate('startDt', startDt, this.setStartDate)
      this.endDt = endDt
      this.setDatePickerDate('endDt', endDt, this.setEndDate)

      if (!this.startDt && !this.endDt) {
        const today = moment().format('YYYY-MM-DD')
        const momentday = moment().add(-1, 'months').format('YYYY-MM-DD')
        this.setDatePickerDate('startDt', momentday, this.setStartDate)
        this.startDt = momentday
        this.setDatePickerDate('endDt', today, this.setEndDate)
        this.endDt = today
      }

      this.pageNumber = pageNumber
    },
  },
  async created() {
    this.initDatePicker('startDt', this.setStartDate)
    this.initDatePicker('endDt', this.setEndDate)

    this.setFromQueryParam()

    await this.search(false)
  },
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
