<template>
  <main class="sub">
    <div class="inner job-posting">
      <div class="tit req-type">
        <h1>정산</h1>
        <p class="stit">호구153에서 정산된 내역들을 확인하실 수 있습니다.</p>
      </div>
      <div class="sub-cont">
        <div class="request-tapmenu-wrap">
          <ul style="cursor: pointer">
            <li v-if="isEmployer">
              <router-link
                :class="{ on: routeName === 'JobDocumentList' }"
                to="/request"
              >
                공고별
              </router-link>
            </li>
            <li>
              <router-link
                :class="{ on: routeName === 'requestList' }"
                to="/requestList"
              >
                근무요청
              </router-link>
            </li>
            <li>
              <router-link
                :class="{ on: routeName === 'attendanceList' }"
                to="/attendance"
              >
                출 &#183; 퇴근
              </router-link>
            </li>
            <li>
              <router-link
                :class="{ on: routeName === 'mypageCalcHistory' }"
                to="/mypage/calc-history"
              >
                정산
              </router-link>
            </li>
          </ul>
        </div>
        <bank-account-edit v-if="false"></bank-account-edit>
        <div v-if="isEmployee">
          <calc-history-tbl-for-employee
            v-if="!isVisibleDetail"
            @showDetailPage="showDetailPage"
          ></calc-history-tbl-for-employee>
          <calc-history-detail-for-employee
            v-else
            :settlementDetail="settlementDetailForEmployee"
          ></calc-history-detail-for-employee>
        </div>
        <div v-else>
          <calc-history-tbl-for-employer
            v-if="!isVisibleDetail"
            @showDetailPage="showDetailPage"
          ></calc-history-tbl-for-employer>
          <calc-history-detail-for-employer
            v-else
            :documentNo="documentNo"
            :settlementDetailList="settlementDetailForEmployer"
            :settlementWorkingsDetail="settlementWorkingsDetail"
          ></calc-history-detail-for-employer>
        </div>
      </div>
    </div>
    <div class="btn-wrap" v-if="isVisibleDetail">
      <a class="link center" @click="hideDetail" style="cursor: pointer"
        ><span>확인</span>
      </a>
    </div>
  </main>
</template>

<script>
import {
  getSettlementDetailForEmployee,
  getSettlementDetailForEmployer,
  getSettlementWorkingsDetail,
} from 'Api/modules'
import CalcHistoryTblForEmployee from '../partials/CalcHistoryTblForEmployee'
import CalcHistoryTblForEmployer from '../partials/CalcHistoryTblForEmployer'
import CalcHistoryDetailForEmployee from '../partials/CalcHistoryDetailForEmployee'
import CalcHistoryDetailForEmployer from '../partials/CalcHistoryDetailForEmployer'
import BankAccountEdit from '../partials/BankAccountEdit'
export default {
  components: {
    CalcHistoryTblForEmployee,
    CalcHistoryTblForEmployer,
    CalcHistoryDetailForEmployee,
    CalcHistoryDetailForEmployer,
    BankAccountEdit, // @TODO 기능 완성 필요
  },
  data() {
    return {
      isVisibleDetail: false,
      documentNo: '',
      settlementDetailForEmployee: {},
      settlementDetailForEmployer: [],
      settlementWorkingsDetail: {
        payConfirmCnt: 0,
        payHoldCnt: 0,
        workingCnt: 0,
        noshowCnt: 0,
        cancelCnt: 0,
        waitCnt: 0,
      },
    }
  },
  watch: {
    $route() {
      this.isVisibleDetail = false
    },
  },
  methods: {
    hideDetail() {
      this.isVisibleDetail = false
    },
    async showDetailPage(settlement) {
      if (this.isEmployee) {
        const resSettlementDetail = await getSettlementDetailForEmployee({
          workingId: settlement.workingId,
        })
        this.settlementDetailForEmployee = resSettlementDetail
      } else {
        this.documentNo = settlement.documentNo
        const resSettlementDetail = await getSettlementDetailForEmployer({
          documentNo: settlement.documentNo,
          // workingDt: settlement.workingDate,
        })
        this.settlementDetailForEmployer = resSettlementDetail
        const resSettlementWorkingsDetail = await getSettlementWorkingsDetail({
          documentNo: settlement.documentNo,
          // workingDt: settlement.workingDate,
        })
        this.settlementWorkingsDetail = resSettlementWorkingsDetail
      }
      this.isVisibleDetail = true
    },
  },
}
</script>

<style></style>
