<template>
  <div class="myp-buy-wrap">
    <div class="search-item-wrap">
      <div class="label">근무일자</div>
      <div class="date-item-wrap">
        <input
          type="text"
          class="inp datepicker"
          inputmode="none"
          id="startDt"
          :value="startDt"
        />
        <span class="e01">-</span>
        <input
          type="text"
          class="inp datepicker"
          inputmode="none"
          id="endDt"
          :value="endDt"
        />
      </div>
      <button type="button" @click="search">검색</button>
    </div>
    <div class="h2-tit type01">
      <h2 class="mshow">정산내역</h2>
      <p class="mshow_sub">
        근무일 기준 1일~15일까지의 급여는 당월 20일, 16일~말일까지의 급여는 익월
        5일 지급됩니다.
      </p>
      <p class="mshow_sub">
        단, 지급 예정일이 주말 및 공휴일일 경우 익일 지급됩니다.
      </p>
    </div>
    <div class="tbl-scrolls">
      <table class="tbl01 type01 mt0">
        <colgroup>
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
          <col width="" />
        </colgroup>
        <tr>
          <th style="width: 100px">근무일자</th>
          <th>근무처명</th>
          <th>발생금액</th>
          <th>지급금액</th>
          <th>지급일자</th>
          <th>상태</th>
        </tr>
        <tr
          v-for="(settlement, idx) in settlementList"
          :key="`st_${idx}`"
          class="pointer"
          @click="showDetailPage(settlement)"
        >
          <td>{{ settlement.workingDate }}</td>
          <td>{{ settlement.workingPlace }}</td>
          <td>{{ `${insertCommaToNumber(settlement.productAmt)}` }}원</td>
          <td>{{ `${insertCommaToNumber(settlement.payAmt)}` }}원</td>
          <td>{{ settlement.payDate }}</td>
          <td>{{ `${settlement.payDate ? '완료' : '미완료'}` }}</td>
          <!--td>
            <button
              type="button"
              class="status hold"
              @click="showDetailPage(settlement)"
            >
              발생내역
            </button>
          </td-->
        </tr>
        <tr>
          <td colspan="6" v-if="settlementList.length == 0">
            정산내역이 존재하지 않습니다.
          </td>
        </tr>
      </table>
      <!-- 페이징 추가 -->
      <Pagination
        :totalElements="totalElements"
        :currentPageIndex="currentPageIndex"
        :listRowCount="listRowCount"
        :pageLinkCount="pageLinkCount"
        @goPage="onGoPage"
      ></Pagination>
    </div>
  </div>
</template>

<script>
import { getSettlementListForEmployee } from 'Api/modules'
import Pagination from 'Common/Pagination'
import datePickerMixin from 'Mixins/datePicker'
import moment from 'moment'
export default {
  components: {
    Pagination,
  },
  mixins: [datePickerMixin],
  watch: {
    startDt(newVal) {
      if (newVal) {
        //endDate가 이미 세팅 되있는 경우
        if (this.endDt) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (this.diffCalendar(newVal, this.endDt) - 1 < 0) {
            this.setDatePickerDate('endDt', newVal, this.setEndDate)
            this.endDt = newVal
          }
        }
      }
    },
    endDt(newVal) {
      if (newVal) {
        //endDate가 이미 세팅 되있는 경우
        if (this.startDt) {
          //endDate가 더 작은지 검사해서 더 작다면 endDate 초기화
          if (this.diffCalendar(this.startDt, newVal) - 1 < 0) {
            this.setDatePickerDate('startDt', newVal, this.setStartDate)
            this.startDt = newVal
          }
        }
      }
    },
    '$route.query': {
      deep: true,
      handler() {
        this.setFromQueryParam()
        this.search(false)
      },
    },
  },
  data() {
    return {
      settlementList: [],
      // paging
      pageNumber: 0,
      totalElements: 0,
      listRowCount: 10,
      pageLinkCount: 5,
      currentPageIndex: 1,
      startDt: '',
      endDt: '',
      PaymentDate: '',
    }
  },
  methods: {
    onGoPage(curPage) {
      this.currentPageIndex = curPage
      this.pageNumber = curPage - 1 //pageNumber 는 현재페이지에서 -1
      this.search()
    },
    showDetailPage(settlement) {
      this.$emit('showDetailPage', settlement)
    },
    setStartDate(selectedDate) {
      this.startDt = selectedDate
    },
    setEndDate(selectedDate) {
      this.endDt = selectedDate
    },
    setFromQueryParam() {
      const { startDt, endDt, pageNumber } = this.$route.query

      this.startDt = startDt
      this.setDatePickerDate('startDt', startDt, this.setStartDate)
      this.endDt = endDt
      this.setDatePickerDate('endDt', endDt, this.setEndDate)
      this.pageNumber = pageNumber
    },
    async search(setQuery = true) {
      if (!this.startDt || !this.endDt) {
        return false
      }
      // 정산 내역 조회
      const res = await getSettlementListForEmployee({
        startDt: this.startDt,
        endDt: this.endDt,
        pageNumber: this.pageNumber,
      })
      this.settlementList = res.content
      this.totalElements = res.totalElements
      this.currentPageIndex = res.pageable.pageNumber + 1
      this.pageNumber = res.pageable.pageNumber

      if (setQuery) {
        const query = {
          startDt: this.startDt,
          endDt: this.endDt,
          pageNumber: this.pageNumber,
        }

        this.$router.push({
          name: 'mypageCalcHistory',
          query,
        })
      }
    },
  },
  async created() {
    this.initDatePicker('startDt', this.setStartDate)
    this.initDatePicker('endDt', this.setEndDate)

    this.setFromQueryParam()

    if (!this.startDt && !this.endDt) {
      const today = moment().format('YYYY-MM-DD')
      const momentday = moment().add(-1, 'months').format('YYYY-MM-DD')
      this.setDatePickerDate('startDt', momentday, this.setStartDate)
      this.startDt = momentday
      this.setDatePickerDate('endDt', today, this.setEndDate)
      this.endDt = today
    }

    await this.search(false)
  },
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>
