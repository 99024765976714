<template>
  <div class="myp-form-wrap type01">
    <dl>
      <dd>
        <div class="form-item-wrap">
          <div class="label">은행명</div>
          <input type="text" class="inp t01" value="" />
        </div>
        <div class="form-item-wrap">
          <div class="label">계좌번호</div>
          <input type="text" class="inp t01" value="" />
        </div>
        <div class="form-item-wrap">
          <div class="label">예금주</div>
          <input type="text" class="inp t01" value="" />
          <button type="button" class="btn-func" @click="save">수정</button>
        </div>
      </dd>
    </dl>
  </div>
</template>

<script>
import { getBankCodeList, getAccountNum, saveAccountNum } from 'Api/modules'
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      bankCode: '',
      bankName: '',
      actHolder: '',
      actNumber: '',
      bankCodeList: [],
    }
  },
  computed: {
    ...mapGetters(['account']),
  },
  methods: {
    changeBank(event) {
      this.bankCode = event.target.value
      this.bankName =
        event.target.options[event.target.options.selectedIndex].text
    },
    async findBankCodeList() {
      this.bankCodeList = await getBankCodeList()
    },
    async save() {
      // 정합성 체크
      if (!this.bankCode) {
        this.$toasted.error('은행을 선택하세요.')
        this.$refs['bank'].focus()
        return
      }

      if (!this.actHolder) {
        this.$toasted.error('예금주를 선택하세요.')
        this.$refs['actHolder'].focus()
        return
      }

      if (!this.actNumber) {
        this.$toasted.error('계좌번호를 선택하세요.')
        this.$refs['actNumber'].focus()
        return
      }

      if (!/^[0-9]*$/.test(this.actNumber)) {
        this.$toasted.error('숫자만 입력하세요.')
        this.$refs['actNumber'].focus()
        return
      }

      const saveData = {
        bankCode: this.bankCode,
        bankName: this.bankName,
        actHolder: this.actHolder,
        actNumber: this.actNumber,
      }

      try {
        await saveAccountNum(saveData)
        this.isSaved = true
        this.$toasted.show('저장되었습니다.')
        return true
      } catch (e) {
        console.error(e)
        return false
      }
    },
  },
  async created() {
    // 은행코드 목록 조회
    await this.findBankCodeList()

    const res = await getAccountNum({ userId: this.account.userId })
    if (res) {
      this.bankCode = res.bankCode
      this.bankName = res.bankName
      this.actHolder = res.actHolder
      this.actNumber = res.actNumber
    }
  },
}
</script>

<style></style>
