<template>
  <div>
    <div class="myp-buy-wrap">
      <div class="tbl-scrolls">
        문서번호 : {{ documentNo }}
        <table class="tbl01">
          <tr>
            <th>지급확정</th>
            <th>지급보류</th>
            <th>근무중</th>
            <th>No-Show</th>
            <th>취소</th>
            <th>대기중</th>
          </tr>
          <tr>
            <td>{{ settlementWorkingsDetail.payConfirmCnt }}</td>
            <td>{{ settlementWorkingsDetail.payHoldCnt }}</td>
            <td>{{ settlementWorkingsDetail.workingCnt }}</td>
            <td>{{ settlementWorkingsDetail.noshowCnt }}</td>
            <td>{{ settlementWorkingsDetail.cancelCnt }}</td>
            <td>{{ settlementWorkingsDetail.waitCnt }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="myp-buy-wrap">
      <div class="tbl-scrolls">
        <table class="tbl01">
          <tr>
            <!--th>No.</th-->
            <th>품목</th>
            <th>발생금액</th>
            <th>VAT</th>
            <th>결제금액</th>
            <th>발생일자</th>
          </tr>
          <tr
            v-for="(settlementDetail, idx) in settlementDetailList"
            :key="`st_${idx}`"
          >
            <!--td>{{ idx + 1 }}</td-->
            <td>{{ settlementDetail.item }}</td>
            <td>
              {{ `${insertCommaToNumber(settlementDetail.productAmt)}` }}원
            </td>
            <td>{{ `${insertCommaToNumber(settlementDetail.vat)}` }}원</td>
            <td>{{ `${insertCommaToNumber(settlementDetail.payAmt)}` }}원</td>
            <td>
              {{
                settlementDetail.item == '총계'
                  ? ''
                  : settlementDetail.occurDate
              }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    documentNo: String,
    settlementDetailList: Array,
    settlementWorkingsDetail: Object,
  },
}
</script>

<style></style>
