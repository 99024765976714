<template>
  <div class="myp-form-wrap type01">
    <dl>
      <dd>
        <div class="form-item-wrap">
          <div class="label">급료</div>
          <div class="value">
            {{ `${insertCommaToNumber(settlementDetail.productAmt)}` }}원
          </div>
        </div>
        <div class="form-item-wrap">
          <div class="label">수수료</div>
          <div class="value">
            -{{ `${insertCommaToNumber(settlementDetail.matchingAmt)}` }}원
          </div>
        </div>
        <div class="form-item-wrap">
          <div class="label">세금</div>
          <div class="value">
            -{{ `${insertCommaToNumber(settlementDetail.vat)}` }}원
          </div>
        </div>
        <div class="form-item-wrap total">
          <div class="label">지급액</div>
          <div class="value">
            {{ `${insertCommaToNumber(settlementDetail.payAmt)}` }}원
          </div>
        </div>
      </dd>
    </dl>
  </div>
</template>

<script>
export default {
  props: {
    settlementDetail: Object,
  },
}
</script>

<style></style>
